import { useEffect, useState } from 'react';
import Button from 'components/button/Button';
import { useControls } from 'components/controls/Controls';
import { Props } from './Actions.interface';

import s from './Actions.module.scss';

const Actions = ({ question, firstStep, lastStep, nextStep, activeCode, currentAnswer }: Props): JSX.Element => {
    const { destinations, back, data } = useControls();
    const next = !lastStep ? data[question].nextStep : activeCode;

    return (
        <div className={s.actions}>
            <div>
                {!firstStep && (
                    <Button
                        role="secondary"
                        onClick={() => back(data)}
                        disabled={firstStep}
                    >
                        Previous
                    </Button>
                )}
            </div>
            <div>
                { !lastStep && Object.entries(destinations).filter(([key, value]) => key === next).map(([key, value]) => (
                    <Button
                        key={`button-${key}`}
                        role="success"
                        onClick={() => value(data)}
                        disabled={currentAnswer === ''}
                    >
                        Next
                    </Button>
                ))}
                { lastStep && currentAnswer !== "" && Object.entries(destinations).filter(([key, value]) => key === next).map(([key, value]) => (
                    <Button
                        key={`button-${key}`}
                        role="success"
                        onClick={() => value(data)}
                        disabled={currentAnswer === ''}
                    >
                        Next
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default Actions;
