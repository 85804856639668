import { createContext } from 'react';

export interface Context {
    activeCode: string;
    setActiveCode: (code: string) => void;
    nextStep: string;
    setNextStep: (step: string) => void;
}

export const QuizContext = createContext({} as Context);
