import { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';

import { useControls } from 'components/controls/Controls';
import { Props } from './Introduction.interface';

import s from './Introduction.module.scss';

const Introduction = ({ destinations }: Props): JSX.Element => {
    const { data } = useControls();

    useEffect(() => {
        const loadingTimer = setTimeout(() => destinations.Q1(data), 4000);
        return () => clearTimeout(loadingTimer);
    },[destinations]);
    
    return (
        <div className={s.introduction}>
            <img
                src="https://www.1stformations.co.uk/blog/wp-content/uploads/2018/11/1st-formations-logo-retina.png"
                alt="1st Formations logo."
                className={s.introduction__logo}
            />
            <h1 className={s.introduction__title}>Find the perfect package</h1>
            <h3 className={s.introduction__subtitle}>Let us help you choose the right <br />package for your company formation</h3>
            <div className={s.introduction__spinner}>
                <ThreeDots color="#31a5cb" />
            </div>
        </div>
    );
};

export default Introduction;
